import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'keyboard_list'
})

export default {
    namespaced: true,
    state: {
        ...crud.state
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}