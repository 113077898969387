import ApiHelper from '@/helpers/ApiHelper'

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async list() {
            return ApiHelper.get(`/api/notification`, {
                filter: {
                    preset: 'admin'
                }
            });
        },
    }
}