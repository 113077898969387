import Login from '@/views/Login.vue'
import Error404 from '@/views/Error404.vue'

import crud from './crud'
import breadcrumbs from './breadcrumbs'

export default [{
        path: "/",
        redirect: "/task"
    }, {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'login'
        }
    },
    // User CRUD
    ..._.flatten(_.map(crud, (item) => {
        return [{
                path: `/${item.module}`,
                component: () => import(`@/views/${item.module}/Index.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].index
                }
            },
            {
                path: `/${item.module}/create`,
                component: () => import(`@/views/${item.module}/Create.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].create
                }
            },
            {
                path: `/${item.module}/update/:id`,
                component: () => import(`@/views/${item.module}/Update.vue`),
                meta: {
                    breadcrumbs: breadcrumbs[item.module].update
                }
            }
        ];
    })),
    {
        path: `/scoring-criteria/task-relations`,
        component: () => import(`@/views/scoring-criteria/TaskRelations.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Критерии оценивания',
                disabled: false,
                href: `/scoring-criteria`,
            },
            {
                text: `Связи с заданиями`,
                disabled: true
            }]
        }
    },
    // Task
    {
        path: `/task`,
        component: () => import(`@/views/task/Index.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Задания',
                disabled: true,
                href: `/task`,
            }]
        }
    },
    {
        path: `/task/themes-upload`,
        component: () => import(`@/views/task/ThemesUpload.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Задания',
                disabled: false,
                href: `/task`,
            },
            {
                text: `Загрузка тем`,
                disabled: true,
                href: `/task/themes-upload`,
            }]
        }
    },
    {
        path: `/task/upload`,
        component: () => import(`@/views/task/Upload.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Задания',
                disabled: false,
                href: `/task`,
            },
            {
                text: `Загрузка заданий`,
                disabled: true,
                href: `/task/upload`,
            }]
        }
    },
    {
        path: `/task/create`,
        component: () => import(`@/views/task/Create.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Задания',
                disabled: false,
                href: `/task`,
            },
            {
                text: `Создание заданий`,
                disabled: true,
                href: `/task/create`,
            }]
        }
    },
    {
        path: `/task/update/:id`,
        component: () => import(`@/views/task/Update.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Задания',
                disabled: false,
                href: `/task`,
            },
            {
                text: 'Редактирование задания',
                disabled: true
            }]
        }
    },
    {
        path: `/task/preview/:id`,
        component: () => import(`@/views/task/Preview.vue`),
        meta: {
            layout: 'print'
        }
    },
    // Notification
    {
        path: `/notification`,
        component: () => import(`@/views/notification/Index.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Уведомления',
                disabled: true,
                href: `/notification`,
            }]
        }
    },
    // Content kits
    {
        path: `/content-kit`,
        component: () => import(`@/views/content-kit/Index.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Наборы контента',
                disabled: true
            }]
        }
    },
    // Job
    {
        path: `/job/update/:id`,
        component: () => import(`@/views/job/Update.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Работы',
                disabled: false,
                href: `/job`,
            },
            {
                text: 'Редактирование работы',
                disabled: true
            }]
        }
    },
    {
        path: `/job/answers-pdf`,
        component: () => import(`@/views/job/AnswersPdf.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Работы',
                disabled: false,
                href: `/job`,
            },
            {
                text: 'Ответы в pdf',
                disabled: true
            }]
        }
    },
    // Job printable
    {
        path: `/job/print`,
        component: () => import(`@/views/job/Print.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Работы',
                disabled: false,
                href: `/job`,
            },
            {
                text: 'Печать',
                disabled: true
            }]
        }
    },
    {
        path: `/job/print/option`,
        component: () => import(`@/views/job/PrintOption.vue`),
        meta: {
            layout: 'print'
        }
    },
    // Job printable
    {
        path: `/media-manager`,
        component: () => import(`@/views/media-manager/index.vue`),
        meta: {
            breadcrumbs: [{
                text: 'Главная',
                disabled: false
            },
            {
                text: 'Медиа менеджер',
                disabled: true
            }]
        }
    },
    // -----
    // Error 404
    {
        path: '*',
        component: Error404,
        meta: {
            breadcrumbs: [{
                    text: 'Главная',
                    disabled: false,
                    href: '/'
                },
                {
                    text: 'Страница не найдена',
                    disabled: true
                }
            ]
        }
    }
]