import createPersistedState from "vuex-persistedstate";

import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import hint from './modules/hint'
import content_kit from './modules/content_kit'
import interactives_instruction from './modules/interactives_instruction'
import task from './modules/task'
import job from './modules/job'
import media_library from './modules/media_library'
import notification from './modules/notification'
import hint_of_subtask from './modules/hint_of_subtask'
import locked_tasks_of_job from './modules/locked_tasks_of_job'
import keyboard_list from './modules/keyboard_list'
import pdf_answer_of_job from './modules/pdf_answer_of_job'

Vue.use(Vuex)

const usePersistedState = () => !process.env.VUE_APP_USE_PERSISTENT_STATE ? null : createPersistedState({
    paths: [
        'user.filter',
        'user.grid',
        'task.filter',
        'task.grid',
        'job.filter',
        'job.grid'
    ]
})

export default new Vuex.Store({
    modules: {
        user,
        hint,
        interactives_instruction,
        content_kit,
        task,
        job,
        media_library,
        notification,
        hint_of_subtask,
        locked_tasks_of_job,
        keyboard_list,
        pdf_answer_of_job
    },
    plugins: [usePersistedState()].filter(p => !!p)
})