export default [{
        module: 'user',
        name: ['пользователи', 'пользователя']
    },
    {
        module: 'job',
        name: ['работы', 'работы']
    },
    {
        module: 'hint',
        name: ['подсказки', 'подсказку']
    },
    {
        module: 'scoring-criteria',
        name: ['элементы', 'элемент']
    },
    {
        module: 'interactives-instruction',
        name: ['инструкции интерактивов', 'инструкции интерактива']
    },
    {
        module: 'keyboard-list',
        name: ['листы вирт. клавиатуры', 'лист вирт. клавиатуры']
    }
]