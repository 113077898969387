import Crud from '@/store/classes/crud'
import ApiHelper from '@/helpers/ApiHelper'

const crud = new Crud({
    module: 'pdf_answer_of_job'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    actions: {
        ...crud.actions,
        async upsert (context, data) {
            return await ApiHelper.post(`/api/pdf-answer-of-job/upsert`, data);
        }
    }
}