import _ from 'lodash'
import ApiHelper from '@/helpers/ApiHelper.js'

export default class {
    constructor(options) {
        const API_MODULE = options.module.replaceAll('_', '-');
        
        this.state = {
            filter: {},
            grid: {
                index: {
                    options: {
                        page: 1,
                        itemsPerPage: 10
                    }
                }
            }
        };
        this.mutations = {
            SET_FILTER(state, data) {
                state.filter = data;
            },
            RESET_FILTER(state) {
                state.filter = _.mapValues(state.filter, () => {
                    return null;
                })
            },
            UPDATE_GRID_OPTIONS(state, data) {
                _.assign(state.grid, data);
            }
        };
        this.actions = {
            filter({commit}, data) {
                commit('SET_FILTER', data);
                return true;
            },
            reset({commit}) {
                commit('RESET_FILTER');
                return true;
            },
            async one(context, data) {
                return ApiHelper.get(`/api/${API_MODULE}/one`, data);
            },
            async list(context, data) {
                return ApiHelper.get(`/api/${API_MODULE}`, data);
            },
            async create(context, data) {
                return await ApiHelper.post(`/api/${API_MODULE}/create`, data);
            },
            async update(context, data) {
                return await ApiHelper.put(`/api/${API_MODULE}/update`, data, {
                    id: _.get(data, 'id', 0)
                });
            },
            async delete(context, data) {
                return await ApiHelper.delete(`/api/${API_MODULE}/delete`, data, {
                    id: _.get(data, 'id', 0)
                });
            },
            updateGridOptions({commit}, data) {
                commit('UPDATE_GRID_OPTIONS', data);
                return true;
            }
        }
    }
}