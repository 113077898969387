import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'content_kit'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {},
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async create(context, data) {
            return await ApiHelper.post('/api/content-kit/create', data);
        },
        async setAsCurrent(context, data) {
            return await ApiHelper.post('/api/content-kit/set-as-current', data);
        }
    }
}