import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
import _ from 'lodash'

const crud = new Crud({
    module: 'task'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        // TODO: необходимость этого поля под вопросом
        initialTaskData: {}, // Копия исходного JSON объекта, для удобного глобального доступа
        // filter: {
        //     active: null,
        //     name: null
        // },
        subjects: [
            {
                value: 'Английский язык',
                text: 'Английский язык',
                grades: [7]
            },
            {
                value: 'Биология',
                text: 'Биология',
                grades: [5, 6, 7, 8]
            },
            {
                value: 'География',
                text: 'География',
                grades: [6, 7, 8]
            },
            {
                value: 'История',
                text: 'История',
                grades: [5, 6, 7, 8]
            },
            {
                value: 'Математика',
                text: 'Математика',
                grades: [4, 5, 6, 7, 8]
            },
            {
                value: 'Обществознание',
                text: 'Обществознание',
                grades: [6, 7, 8]
            },
            {
                value: 'Окружающий мир',
                text: 'Окружающий мир',
                grades: [4]
            },
            {
                value: 'Русский язык',
                text: 'Русский язык',
                grades: [4, 5, 6, 7, 8]
            },
            {
                value: 'Физика',
                text: 'Физика',
                grades: [7, 8]
            },
            {
                value: 'Химия',
                text: 'Химия',
                grades: [8]
            }
        ]
    },
    mutations: {
        ...crud.mutations,
        setInitialTaskData(state, data) {
            if (_.isObject(data)) {
                state.initialTaskData = {...data}
            }
        }
    },
    actions: {
        ...crud.actions,
        async upload(context, data) {
            return await ApiHelper.post(`/api/task/upload`, data);
        },
        async themesUpload(context, data) {
            return await ApiHelper.post(`/api/task/themes-upload`, data);
        },
        async fetchThemes() {
            return await ApiHelper.get(`/api/task/themes`);
        },
        async fetchSubtaskHints(context, data) {
            return await ApiHelper.get(`/api/hint-of-subtask`, data);
        },
    },
    getters: {
        grades: () => {
            return _.map(_.keys(Array(11)), (i) => {
                i = _.parseInt(i);
                return {
                    value: i + 1,
                    text: (i + 1) + ' кл.'
                };   
            })
        }
    }
}