import _ from 'lodash'
import crud from './crud'

let breadcrumbs = {}

// Crud items
_.map(crud, (item) => {
    _.set(breadcrumbs, `${item.module}.index`, [{
            text: 'Главная',
            disabled: true
        },
        {
            text: _.upperFirst(_.get(item, 'name.0', 'элементы')),
            disabled: false,
            href: `/${item.module}`,
        }
    ]);
    _.set(breadcrumbs, `${item.module}.create`, [
        ...breadcrumbs[item.module].index,
        ...[{
            text: `Создание ${_.get(item, 'name.1', 'элемента')}`,
            disabled: false,
            href: `/${item.module}/create`,
        }]
    ])
    _.set(breadcrumbs, `${item.module}.update`, [
        ...breadcrumbs[item.module].index,
        ...[{
            text: `Редактирование ${_.get(item, 'name.1', 'элемента')}`,
            disabled: false,
            href: `/${item.module}/update/:id`,
        }]
    ])
})

export default breadcrumbs