<template>
    <component :is="activeLayout">
        <slot />
    </component>
</template>
<script>
export default {
    name: 'Layout',
    data() {
        return {
            layouts: {
                default: () => import('@/layouts/Default.vue'),
                login: () => import('@/layouts/Login.vue'),
                print: () => import('@/layouts/Print.vue')
            }
        }
    },
    computed: {
        activeLayoutCode() {
            return _.get(this.$route, 'meta.layout', 'default');
        },
        activeLayout() {
            return this.layouts[this.activeLayoutCode];
        }
    }
}
</script>