<template>
    <v-row align="center" justify="stretch" dark>
        <v-col cols="12">
            <div class="text-h6 mb-5">Ошибка 404</div>
            <v-alert type="error">
                Страница не найдена.
            </v-alert>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'Error404'
}
</script>