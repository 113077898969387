import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'job'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filter: {},
        subjects: [{
                value: 'Физика',
                text: 'Физика',
                grades: [7, 8]
            },{
                value: 'Английский язык',
                text: 'Английский язык',
                grades: [7]
            },{
                value: 'Биология',
                text: 'Биология',
                grades: [5, 6, 7, 8]
            },
            {
                value: 'География',
                text: 'География',
                grades: [6, 7, 8]
            },
            {
                value: 'История',
                text: 'История',
                grades: [5, 6, 7, 8]
            },
            {
                value: 'Математика',
                text: 'Математика',
                grades: [4, 5, 6, 7, 8]
            },
            {
                value: 'Обществознание',
                text: 'Обществознание',
                grades: [6, 7, 8]
            },
            {
                value: 'Окружающий мир',
                text: 'Окружающий мир',
                grades: [4]
            },
            {
                value: 'Русский язык',
                text: 'Русский язык',
                grades: [4, 5, 6, 7, 8]
            },
            {
                value: 'Химия',
                text: 'Химия',
                grades: [8]
            }
        ]
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    },
    getters: {
        grades: () => {
            return _.map(_.keys(Array(11)), (i) => {
                i = _.parseInt(i);
                return {
                    value: i + 1,
                    text: (i + 1) + ' кл.'
                };
            })
        }
    }
}