import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index.js'
import routes from './routes.js'

// Init 
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Events 
router.beforeEach(async (to, from, next) => {
    // Unauthorized redirect to login page
    if (!store.getters['user/isAuthorized'] && to.name !== 'Login') {
        next('/login');
        return;
    }

    next();
});

export default router