<template>
    <layout>
        <router-view :key="$route.path" />
    </layout>
</template>
<script>
import Layout from '@/components/template/Layout.vue'
export default {
    name: 'App',
    components: {
        Layout
    }
};
</script>