import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'hint_of_subtask'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
    },
    actions: {
        ...crud.actions
    }
}