import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'

const crud = new Crud({
    module: 'media_library'
})

export default {
    namespaced: true,
    state: {},
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async search(context, {title, path, type}) {
            return await ApiHelper.post('/api/media-library/media', {
                offset: 0, limit: 20, type, title, path
            });
        },
        async media(context, data) {
            return await ApiHelper.post('/api/media-library/media', data);
        },
        async upload(context, data) {
            return await ApiHelper.post('/api/media-library/upload', data);
        },
        async replace(context, data) {
            return await ApiHelper.post('/api/media-library/replace', data);
        },
    }
}